import { createTheme, ThemeProvider } from "@mui/material";
import * as React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { SessionExpired } from "./components/SessionExpired";
import GuestData from "./guests/GuestData";
import GuestRegistration from "./guests/GuestRegistration";
import Accommodations from "./information/Accommodations";
import InformationOverview from "./information/InformationOverview";
import Locations from "./information/Locations";
import Presents from "./information/Presents";
import TimeLinePage from "./information/TimeLinePage";
import LandingPage from "./overview/LandingPage";
import { useLocalStorage } from "./utils/useLocalStorage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9FA696",
    },
    secondary: {
      main: "#E4867C",
    },
  },
});

const Protected = ({ isLoggedIn, children }: { isLoggedIn: boolean, children: JSX.Element }): JSX.Element => {
  if(!isLoggedIn) {
    return <Navigate to="/" replace/>;
  }
  return children;
};

function App(): JSX.Element {

  const [token, setToken] = useLocalStorage(
    "token",
    window.localStorage.getItem("token") || null,
  );

  const isLoggedIn = !!token;

  return (
    <ThemeProvider theme={ theme }>
      <HashRouter>
        <Routes>
          <Route index element={ <LandingPage isLoggedIn={ isLoggedIn } setToken={ setToken }/> }/>
          <Route path="guests" element={ <GuestData/> }/>
          <Route path="registration" element={ <GuestRegistration/> }/>
          <Route path="information" element={ <Protected isLoggedIn={ isLoggedIn }><InformationOverview/></Protected> }/>
          <Route path="locations" element={ <Protected isLoggedIn={ isLoggedIn }><Locations/></Protected> }/>
          <Route path="presents" element={ <Protected isLoggedIn={ isLoggedIn }><Presents/></Protected> }/>
          <Route path="timeline" element={ <Protected isLoggedIn={ isLoggedIn }><TimeLinePage/></Protected> }/>
          <Route path="accommodations" element={ <Protected isLoggedIn={ isLoggedIn }><Accommodations/></Protected> }/>
          <Route path="/error" element={ <SessionExpired setToken={ setToken }/> }/>
          <Route path="*" element={ <Navigate to="/" replace/> }/>
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
