import { gql } from "@apollo/client";

export const GET_GUESTS_FROM_INVITATIONS = gql`
    query GetMyInvitation {
        myInvitation {
            guid
            provideSnacks
            snackSuggestions
            provideEntertainment
            photoUploadLink
            guests {
                id
                age
                confirmed
                name
                eatingBehaviour
                foodIntolerances {
                    name
                }
            }
        }
    }
`;

export const GET_MARRIAGE_WITNESSES = gql`
    query GetMarriageWitnesses {
        allMarriageWitnesses {
            id,
            name,
            phoneNumber,
            eMailAddress
        }
    }
`;
