import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import SimpleAppBar from "../components/SimpleAppBar";
import TimeLine from "./TimeLine";

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 24px;
`;

const SmallerHeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url("time.jpg");

  /* Set a specific height */
  height: 20%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const SmallHeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FAD5B3;
  font-size: 24px;
`;

const SimpleLayout = styled.div`
  padding: 12px;
`;

function TimeLinePage(): JSX.Element {
  return (
    <div className="App">
      <SimpleAppBar backPath="/information"/>
      <SmallerHeroImage>
        <SmallHeroText>
          Zeitplan
        </SmallHeroText>
      </SmallerHeroImage>
      <SimpleLayout>
        <Typography variant="subtitle1">
          Auf dieser Seite findet ihr den voraussichtlichen Zeitplan.
        </Typography>
        <Vertical>
          <TimeLine/>
        </Vertical>
      </SimpleLayout>
    </div>
  );
}

export default TimeLinePage;
