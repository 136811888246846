import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Alert, Box, Button, Typography } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import SimpleAppBar from "../components/SimpleAppBar";
import { Spacing } from "../components/Spacing";
import {
  selectAllGuestInfoSettled,
  selectContribution,
  selectGuests,
  setContribution,
  setGuests,
  setInvitationId,
} from "../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../data/hooks";
import { GET_GUESTS_FROM_INVITATIONS } from "../data/queries";
import { Footer } from "./components/Footer";
import GuestCard from "./components/GuestCard";

const CenteredLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GridLayout = styled.div`
  min-height: 50px;
  display: grid;
  gap: 5px;
  row-gap: 10px;
  padding: 15px;
  grid: auto / auto  auto;
  max-width: 300px;
`;

const InformationBanner = (): JSX.Element => <Alert variant="outlined" severity="warning" sx={ { maxWidth: "310px", marginBottom: 3 } }>
  Bitte beachtet, dass wir eine Rückmeldung von euch bis zum 15.06.2022 benötigen.
</Alert>;

function GuestData(): JSX.Element {
  const { loading, error, data } = useQuery(GET_GUESTS_FROM_INVITATIONS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allInfoSet = useAppSelector(selectAllGuestInfoSettled);
  const guests = useAppSelector(selectGuests);
  const contributions = useAppSelector(selectContribution);

  useEffect(() => {
    if(data) {
      const { guests, guid, ...rest } = data.myInvitation;
      dispatch(setGuests(guests));
      dispatch(setInvitationId(guid));
      dispatch(setContribution({ ...rest }));
    }
  }, [data, loading, dispatch]);

  if(error) {
    return <Navigate to="/error" replace/>;
  }

  if(loading || (guests.length === 0)) {
    return <Loading/>;
  }

  return (
    <div className="App">
      <SimpleAppBar backPath="/"/>
      <Typography variant="h4" sx={ { marginTop: "16px", marginBottom: "16px" } }>Eure Daten</Typography>

      <CenteredLayout>
        { !allInfoSet && <InformationBanner/> }
        <Typography sx={ { fontSize: "14px", marginBottom: "16px", maxWidth: "344px" } }>
          Ihr könnt eure Daten jederzeit korrigieren, indem ihr auf den Knopf "Jetzt Bearbeiten"
          rechts unten klickt. <br/> Eine Zu- oder Absage ist nach dem 15.06.2022 verbindlich.</Typography>
        { guests.map(guest => <GuestCard key={ guest.id } guest={ guest }/>) }
      </CenteredLayout>

      <Spacing/>
      <Spacing/>
      { allInfoSet && <CenteredLayout>
        <h2>Beiträge</h2>

        <GridLayout>
          <Typography sx={ { fontSize: 15, textAlign: "left" } } color="text.secondary">
            Programmbeitrag:
          </Typography>
          <Typography sx={ { marginLeft: 1.5, fontSize: 15 } } color="text.primary">
            { contributions?.provideEntertainment ? "Ja" : "Nein" }
          </Typography>
          <Typography sx={ { fontSize: 15, textAlign: "left" } } color="text.secondary">
            Beitrag Sektempfang:
          </Typography>
          <Typography sx={ { marginLeft: 1.5, fontSize: 15 } } color="text.primary">
            { contributions?.provideSnacks ? "Ja" : "Nein" }
          </Typography>
          <Typography sx={ { fontSize: 15, textAlign: "left" } } color="text.secondary">
            Snackideen:
          </Typography>
          <Typography sx={ { marginLeft: 1.5, fontSize: 15, textAlign: "left" } } color="text.primary">
            { contributions?.snackSuggestions ? contributions.snackSuggestions : "-" }
          </Typography>
        </GridLayout>

      </CenteredLayout> }
      <Box sx={ { height: "60px" } }/>
      <Spacing/>
      <Footer>
        <Box sx={ { display: "flex", flexDirection: "row", pt: 4, justifyContent: "center" } }>
          <Button
            variant="outlined"
            onClick={ () => navigate("/") }
            sx={ { mb: "12px", minWidth: "20ch", background: "#fff", marginRight: "8px", height: "48px" } }
          >
            Zur Übersicht
          </Button>
          <Button
            variant="contained"
            onClick={ () => navigate("/registration") }
            sx={ { mb: "12px", minWidth: "20ch", marginLeft: "8px", height: "48px" } }
          >
            Jetzt bearbeiten
          </Button>
        </Box>
      </Footer>
    </div>
  );
}

export default GuestData;
