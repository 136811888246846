import { ThunkDispatch } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import type { AppDispatch, RootState } from "./store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): Dispatch<AnyAction>
  & ThunkDispatch<any, null, AnyAction> // eslint-disable-line @typescript-eslint/no-explicit-any
  & ThunkDispatch<any, undefined, AnyAction> => useDispatch<AppDispatch>(); // eslint-disable-line @typescript-eslint/no-explicit-any
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
