import styled from "@emotion/styled";

export const Footer = styled.div`
  bottom: 0;
  width: 100%;
  position: fixed;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%,
  rgba(255, 255, 255, 0.5) 30%,
  rgba(255, 255, 255, 1) 35%,
  rgba(255, 255, 255, 1));
  padding: 0;
`;
