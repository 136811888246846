import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonHero } from "./ButtonHero";

const YellowHero = styled(ButtonHero)`
  background-color: #FAD5B3;
`;

export const UpdateDataHero = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <YellowHero>
      <Typography sx={ { color: "#A2856D", margin: "4px 14px 4px 14px", filter: "brightness(70%)", maxWidth: "420px" } }>
        Gebt uns bis zum 15.06.2022 Bescheid, ob wir mit Euch rechnen können.
        Die eingegebenen Daten können jederzeit korrigiert werden.</Typography>
      <Button variant="outlined"
        sx={ { height: "46px", color: "#A2856D", filter: "brightness(70%)", border: "2px solid #A2856D", marginTop: "8px" } }
        onClick={ () => navigate("/guests") }>
        Zur Rückmeldung
      </Button>
    </YellowHero>
  );
};
