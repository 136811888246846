import styled from "@emotion/styled";
import { Favorite } from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import "../App.css";
import Hero from "../components/Hero";
import { InformationHero } from "./components/InformationHero";
import { LoginHero } from "./components/LoginHero";
import { UpdateDataHero } from "./components/UpdateDataHero";

const BannerText = styled.div`
  min-height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  margin: 0;
`;

const OverviewBannerText = styled(BannerText)`
  min-height: 10%;
`;

const LoginPart = ({ setToken }: { setToken: (secret: string | null) => void }): JSX.Element => (<>
  <BannerText>
    <Typography variant="h6" sx={ { marginTop: "4px", marginBottom: "4px", lineHeight: "1.4" } }>
      Wir freuen uns unseren Hochzeitstag <br/> mit euch zu verbringen
    </Typography>
    <Favorite sx={ { color: "#F7B1B4" } }/>
  </BannerText>
  <Divider/>
  <LoginHero setToken={ setToken }/>
  <Divider/>
</>);

const OverviewPart = (): JSX.Element => (<>
  <OverviewBannerText>
    <Typography variant="h6" sx={ { marginTop: "4px", marginBottom: "4px", lineHeight: "1.4" } }>
      Hallo! Freut uns, dass ihr hier seid.
    </Typography>
  </OverviewBannerText>
  <Divider/>
  <UpdateDataHero/>
  <Divider/>
  <InformationHero/>
</>);

function LandingPage({ isLoggedIn, setToken }: { isLoggedIn: boolean, setToken: (secret: string | null) => void }): JSX.Element {
  return (
    <PageLayout>
      <Hero isLoggedIn={ isLoggedIn }/>
      { isLoggedIn ? <OverviewPart/> : <LoginPart setToken={ setToken }/> }
    </PageLayout>
  );
}

export default LandingPage;
