import { TextField } from "@mui/material";
import { useState } from "react";
import { ErrorSnack } from "../../components/ErrorSnack";
import { setAge } from "../../data/guestSlice";
import { useAppDispatch } from "../../data/hooks";

export const AgeTextField = ({ age, guestName }: { age: number | undefined, guestName: string }): JSX.Element => {
  const [snackOpen, setSnackOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if(reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };
  return <>
    <TextField
      required
      size="small"
      label="Alter"
      value={ age || "" }
      onChange={ (event) => {
        const typedAge = parseInt(event.target.value);
        if(typedAge > 100) {
          setSnackOpen(true);
          return;
        }
        dispatch(setAge([guestName, typedAge]));
        setSnackOpen(false);
      } }
      inputProps={ { inputMode: "numeric", pattern: "[0-9]" } }
      sx={ { m: 1, width: "8ch", zIndex: 0 } }
    />
    <ErrorSnack text={ `${ guestName } kann nicht älter als 100 Jahre alt sein ;)` } open={ snackOpen } handleClose={ handleClose }/>
  </>;
};
