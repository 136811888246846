import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import * as React from "react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Loading } from "../components/Loading";
import SimpleAppBar from "../components/SimpleAppBar";
import {
  clearGuestInformation,
  EatingInformationPairs,
  GuestContribution,
  selectAllDeclined,
  selectConfirmedGuests,
  selectEatingInformation,
  setContribution,
  setGuests,
} from "../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../data/hooks";
import { GET_GUESTS_FROM_INVITATIONS } from "../data/queries";
import { Footer } from "./components/Footer";
import { ContributionStep, FoodStep, Summary, Zusage } from "./registration-steps";

const steps = ["Rückmeldung", "Essen", "Beiträge"];

export default function GuestRegistration(): JSX.Element {
  const { loading, error, data } = useQuery(GET_GUESTS_FROM_INVITATIONS);
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const allGuestsDeclined = useAppSelector(selectAllDeclined);
  const confirmedGuests = useAppSelector(selectConfirmedGuests);
  const eatingInfoPairs = useAppSelector(selectEatingInformation) || {};
  const navigate = useNavigate();

  useEffect(() => {
    if(data) {
      dispatch(setGuests(data.myInvitation.guests));
      const { provideEntertainment, provideSnacks, snackSuggestions } = data.myInvitation;
      const contribution: GuestContribution = { provideEntertainment, provideSnacks, snackSuggestions };
      dispatch(setContribution(contribution));
    }
  }, [data, loading, dispatch]);

  if(error) {
    return <Navigate to="/error" replace/>;
  }
  if(loading) {
    return <Loading/>;
  }

  const isStepSkipped = (step: number): boolean => {
    return skipped.has(step);
  };

  const handleNext = (): void => {
    if(guestDeclinedStep(activeStep, allGuestsDeclined)) {
      setActiveStep(() => steps.length);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep + 1);
        newSkipped.add(activeStep + 2);
        return newSkipped;
      });
      return;
    }

    let newSkipped = skipped;
    if(isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = (): void => {
    if(activeStep === 0) {
      dispatch(clearGuestInformation());
      navigate("/guests");
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (): void => {
    setActiveStep(0);
  };

  return (
    <Box sx={ { width: "100%" } }>
      <SimpleAppBar backPath="/guests"/>
      <Stepper sx={ { marginTop: "16px" } } activeStep={ activeStep } alternativeLabel>
        { steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};

          if(isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={ label } { ...stepProps }>
              <StepLabel>{ label }</StepLabel>
            </Step>
          );
        }) }
      </Stepper>
      { (activeStep === steps.length) ? (
        <Summary handleReset={ handleReset }/>
      ) : (
        <React.Fragment>
          { (activeStep === 0) && <Zusage/> }
          { (activeStep === 1) && <FoodStep/> }
          { (activeStep === 2) && <ContributionStep/> }
          <Box sx={ { height: "48px" } }/>
          <Footer>
            <Box sx={ { display: "flex", flexDirection: "row", pt: 4, justifyContent: "center" } }>
              <Button
                variant="outlined"
                onClick={ handleBack }
                sx={ { mb: "12px", minWidth: "20ch", background: "#fff", marginRight: "8px", height: "48px", zIndex: 1020 } }
              >
                { (activeStep === 0) ? "Abbrechen" : "Zurück" }
              </Button>
              <Button
                variant="contained"
                disabled={ !isNextStepAvailable(activeStep, eatingInfoPairs, confirmedGuests) }
                onClick={ handleNext }
                sx={ {
                  mb: "12px",
                  minWidth: "20ch",
                  marginLeft: "8px",
                  height: "48px",
                  zIndex: 1020,
                } }
              >
                { setNextLabel(activeStep, allGuestsDeclined) }
              </Button>
            </Box>
          </Footer>
        </React.Fragment>
      ) }
    </Box>
  );
}

const isNextStepAvailable = (activeStep: number, eatingInformation: EatingInformationPairs, allConfirmedGuests: string[]): boolean => {
  let isAllowed = true;
  if(activeStep !== 1) {
    return isAllowed;
  }
  for(const guest of allConfirmedGuests) {
    const guestFoodInfo = eatingInformation[guest];
    if(!guestFoodInfo?.age || !guestFoodInfo?.eatingBehaviour) {
      isAllowed = false;
      break;
    }
  }
  return isAllowed;
};

const guestDeclinedStep = (activeStep: number, allGuestsDeclined: boolean): boolean => (activeStep === 0) && allGuestsDeclined;

const setNextLabel = (activeStep: number, allGuestsDeclined: boolean): string => {
  if(guestDeclinedStep(activeStep, allGuestsDeclined)) {
    return "Absagen";
  }
  if(activeStep === steps.length - 1) {
    return "Angaben prüfen";
  }
  return "Weiter";
};
