import styled from "@emotion/styled";
import { FactCheckOutlined, SavingsTwoTone, ShoppingBagTwoTone } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import SimpleAppBar from "../components/SimpleAppBar";

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 24px;
`;

const SimpleLayout = styled.div`
  padding: 12px;
`;

const SmallerHeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url("wish_couple.jpg");

  /* Set a specific height */
  height: 20%;

  /* Position and center the image to scale nicely on all screens */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const SmallHeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FAD5B3;
  font-size: 24px;
`;

function Presents(): JSX.Element {
  return (
    <div className="App">
      <SimpleAppBar backPath="/information"/>
      <SmallerHeroImage>
        <SmallHeroText>
          Wunschliste des Brautpaars
        </SmallHeroText>
      </SmallerHeroImage>
      <SimpleLayout>
        <Typography variant="subtitle1" sx={ { marginBottom: "12px" } }>
          Hier ein paar Geschenkideen um die Suche zu erleichtern.
        </Typography>
        <Divider variant="middle"/>
        <Vertical>
          <Paper elevation={ 3 } sx={ { width: "80%", padding: "26px", marginBottom: "20px", marginTop: "8px" } }>
            Unser Haushalt ist komplett,
            <br/>
            aber unser Sparschwein wär gern fett.
            <br/>
            Darum lasst das Schenken sein -<br/> außer Geld, das wär ganz fein! <br/> <br/>
            <SavingsTwoTone sx={ { color: "#E4867C" } }/>
          </Paper>
          <Typography variant="body2" sx={ { marginBottom: "24px" } }>
            Damit wir unseren Traum vom Eigenheim irgendwann verwirklichen können
            :)
          </Typography>
          <Divider variant="middle" sx={ { minWidth: "95%" } }/>
          <Paper elevation={ 3 } sx={ { width: "80%", padding: "26px", marginBottom: "20px", marginTop: "16px" } }>
            Wer dennoch meint, er müsste zum Geld eine Kleinigkeit schenken,
            <br/>
            der braucht nicht über's "Wie" und "Was" nachzudenken.
            <br/>
            Einen kurzen Blick auf die Wunschliste des Brautpaars werfen, <br/>
            erspart jedem eine Sucherei und strapazierte Nerven! <br/> <br/>
            <ShoppingBagTwoTone sx={ { color: "#A2856D" } }/>
          </Paper>
          <Typography variant="body2" sx={ { marginBottom: "12px" } }>
            Die Wunschliste* findet ihr hier: <br/>
            <Button variant="contained" endIcon={ <FactCheckOutlined></FactCheckOutlined> }
              href="https://www.wunschzettel.de/Jenny-und-Michi" target="_blank" rel="noreferrer">Zum Wunschzettel
            </Button>
          </Typography>
          <Typography variant="caption">
            * Bitte denkt daran die Dinge abzuhaken, die ihr besorgen wollt, damit
            keine Sachen doppelt geschenkt werden und keine Enttäuschung auf
            beiden Seiten bei der Übergabe entstehen kann.
          </Typography>
        </Vertical>
      </SimpleLayout>
    </div>
  );
}

export default Presents;
