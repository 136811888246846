import { Alert, Snackbar } from "@mui/material";

export const ErrorSnack = ({ open, handleClose, text }: { open: boolean, handleClose: () => void, text: string }): JSX.Element => <Snackbar
  open={ open }
  autoHideDuration={ 6000 }
  onClose={ handleClose }>
  <Alert onClose={ handleClose } severity="error" sx={ { width: "100%" } }>
    { text }
  </Alert>
</Snackbar>;
