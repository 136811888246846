import { useMutation } from "@apollo/client";
import { ExpandMore, SentimentVeryDissatisfied } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as React from "react";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CenteredLayout } from "../../components/CenteredLayout";
import {
  clearGuestInformation,
  EatingBehaviour,
  EatingBehaviourText,
  selectAllDeclined,
  selectConfirmedGuests,
  selectConfirmedPairs,
  selectContribution,
  selectEatingInformation,
  selectGuests,
  selectInvitationId,
  updateGuest,
} from "../../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../../data/hooks";
import { UPDATE_GUEST, UPDATE_INVITATION } from "../../data/mutations";
import { GET_GUESTS_FROM_INVITATIONS } from "../../data/queries";
import { Footer } from "../components/Footer";
import { StepsLayout } from "../StepsLayout";
import { Absage } from "./Absage";

export function SimpleAccordion(): JSX.Element {
  const confirmedGuests = useAppSelector(selectConfirmedGuests);
  const [expanded, setExpanded] = useState(confirmedGuests);
  const guests = useAppSelector(selectGuests);
  const confirmedPairs = useAppSelector(selectConfirmedPairs);
  const foodInfo = useAppSelector(selectEatingInformation);

  const handleChange = (guestName: string): void => {
    const isExpanded = expanded.some(name => name === guestName);
    if(isExpanded) {
      setExpanded(expanded.filter(name => name !== guestName));
    } else {
      const confirmed = confirmedGuests.find(name => name === guestName);
      confirmed && setExpanded([...expanded, guestName]);
    }
  };

  return (
    <div>
      { guests.map(({ name: guestName }) =>
        <Accordion expanded={ expanded.some(name => name === guestName) } onClick={ () => handleChange(guestName) } elevation={ 5 }
          disableGutters key={ guestName }>
          <AccordionSummary
            expandIcon={ confirmedPairs?.[guestName] ? <ExpandMore/> : <SentimentVeryDissatisfied/> }
            aria-controls={ `${ guestName }-controls` }
            id={ guestName }>
            <Typography sx={ { width: "60%", flexShrink: 0 } }>{ guestName }</Typography>
            <Chip
              variant="outlined"
              color={ confirmedPairs?.[guestName] ? "success" : "error" }
              label={ confirmedPairs?.[guestName] ? "Zugesagt" : "Abgesagt" }
              sx={ { marginLeft: 1.5 } }
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={ { display: "flex" } }>
              <Typography>
                Alter: { foodInfo?.[guestName]?.age }
              </Typography>
              <Typography sx={ { marginLeft: 5 } }>
                Ernährung: { EatingBehaviourText[foodInfo?.[guestName]?.eatingBehaviour || 0] }
              </Typography>
            </Box>

            <Typography>
              Unverträglichkeiten: { !foodInfo?.[guestName]?.foodIntolerances?.length && "Keine" }
              { foodInfo?.[guestName]?.foodIntolerances?.map(intolerance => <li
                key={ guestName + intolerance.name }>{ intolerance.name }</li>) }
            </Typography>
          </AccordionDetails>
        </Accordion>) }
    </div>
  );
}

export const Summary = ({ handleReset }: { handleReset: () => void }): JSX.Element => {
  const [updateGuestMutation] = useMutation(UPDATE_GUEST);
  const [updateInvitationMutation] = useMutation(UPDATE_INVITATION, { refetchQueries: [{ query: GET_GUESTS_FROM_INVITATIONS }] });
  const dispatch = useAppDispatch();
  const foodInfo = useAppSelector(selectEatingInformation);
  const guests = useAppSelector(selectGuests);
  const confirmedPairs = useAppSelector(selectConfirmedPairs);
  const allGuestsDeclined = useAppSelector(selectAllDeclined);
  const contributions = useAppSelector(selectContribution);
  const invitationId = useAppSelector(selectInvitationId);
  const navigate = useNavigate();

  const handleSubmit = (): void => {
    Promise.allSettled(guests.map(guest => {
      const updatedGuest = {
        id: guest.id,
        ...foodInfo?.[guest.name],
        eatingBehaviour: EatingBehaviour[foodInfo?.[guest.name]?.eatingBehaviour || 1],
        confirmed: confirmedPairs?.[guest.name],
      };
      const variables = { guest: updatedGuest };
      return updateGuestMutation({ variables }).then(({ data }) => dispatch(updateGuest(data.updateGuest)));
    })).then(() => updateInvitationMutation({ variables: { invitation: { id: invitationId, ...contributions } } })).catch().finally(() => {
      dispatch(clearGuestInformation());
      navigate("/guests");
    });
  };

  return <Fragment>

    <StepsLayout>
      <Alert severity="info">Bitte überprüft eure Angaben.</Alert>
      <CenteredLayout>
        <Box sx={ { minWidth: "300px" } }>
          { allGuestsDeclined && <Absage/> }
          { !allGuestsDeclined && <Fragment>
            <Box sx={ { paddingTop: "12px" } }>
              <Typography>Beitrag Programmpunkt: { contributions?.provideEntertainment ? "Ja" : "Nein" }</Typography>
              <Typography>Beitrag Sektempfang: { contributions?.provideSnacks ? "Ja" : "Nein" }</Typography>
            </Box>
            { (!!contributions?.snackSuggestions?.trim().length) &&
              <Typography>Snackvorschläge: { contributions?.snackSuggestions }</Typography> }
            <Typography sx={ { mt: 2, mb: 1 } }>
              Gästeliste:
            </Typography>
            <SimpleAccordion/>

          </Fragment> }
        </Box>
      </CenteredLayout>
    </StepsLayout>

    <Footer>
      <Box sx={ { display: "flex", flexDirection: "row", pt: 4 } }>
        <Button variant="outlined" onClick={ handleReset } sx={ { ml: 1, mb: 0.2, minWidth: "20ch", background: "#fff" } }>Anpassen</Button>
        <Box sx={ { flex: "1 1 auto" } }/>
        <Button
          variant="contained"
          sx={ { mr: 1, mb: 0.2, minWidth: "20ch" } } onClick={ handleSubmit }>
          Abschicken
        </Button>
      </Box>
    </Footer>
  </Fragment>;
};
