import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import * as React from "react";
import SimpleAppBar from "../components/SimpleAppBar";

function WalkCard(): JSX.Element {
  return (
    <Box sx={ { textAlign: "left", marginBottom: "12px", marginTop: "12px" } }>
      <Card sx={ { minWidth: 275 } } raised>
        <CardContent>
          <Typography sx={ { mb: 1 } } variant="h5" component="div">
            Walksches Haus
          </Typography>
          <Typography sx={ { mb: 1.5, fontSize: 14 } } color="text.secondary">
            Telefon: +49 (0) 7244 - 70 37 - 0 <br/>
            E-Mail: info@walksches-haus.de
          </Typography>
          <Typography variant="body2" sx={ { marginTop: "8px" } }>Hochzeitsgäste bekommen zu einem vergünstigtem Tarif das Doppelzimmer
            inkl. Frühstück für 120€ und das Einzelzimmer für 75€ (Stand: 24.04.2022).</Typography>
          <Typography variant="body2" sx={ { marginTop: "8px" } }>BITTE BEACHTET: Reservierungen werden bevorzugt per E-Mail entgegen
            genommen. Falls man allerdings Fragen haben sollte, kann man natürlich auch anrufen.</Typography>
        </CardContent>
        <CardActions>
          <Button href="https://www.walksches-haus.de/" size="small" sx={ { color: "#F7B1B4", fontWeight: 600 } }>Zur Website</Button>
        </CardActions>
      </Card>
    </Box>
  );
}

function KroneCard(): JSX.Element {
  return (
    <Box sx={ { textAlign: "left", marginBottom: "12px" } }>
      <Card sx={ { minWidth: 275 } } raised>
        <CardContent>
          <Typography sx={ { mb: 1 } } variant="h5" component="div">
            Gasthaus zur Krone
          </Typography>
          <Typography sx={ { mb: 1.5, fontSize: 14 } } color="text.secondary">
            Telefon: +49 (0) 7244-947380 <br/>
            E-Mail: info@krone-weingarten.de
          </Typography>
          <Typography variant="body2" sx={ { marginTop: "8px" } }>Das Gasthaus zur Krone ist schräg gegenüber von der Feierlocation
            "Walksches Haus". Der Weg sollte zur Not auch schwankend
            machbar sein.</Typography>
        </CardContent>
        <CardActions>
          <Button href="https://www.krone-weingarten.de/" size="small" sx={ { color: "#F7B1B4", fontWeight: 600 } }>Zur Website</Button>
        </CardActions>
      </Card>
    </Box>
  );
}

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
`;

const SmallerHeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url("night.jpg");

  /* Set a specific height */
  height: 20%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const SmallHeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FAD5B3;
  font-size: 24px;
`;

function Accommodations(): JSX.Element {
  return (
    <div className="App">
      <SimpleAppBar backPath="/information"/>

      <SmallerHeroImage>
        <SmallHeroText>
          Übernachtungsmöglichkeiten
        </SmallHeroText>
      </SmallerHeroImage>

      <Vertical>
        <Typography variant="subtitle1">
          Auf dieser Seite findet ihr einige Anlaufstellen für Übernachtungsmöglichkeiten. <br/>
          Im Walkschen Haus selbst haben wir natürlich auch ein begrenztes Kontigent an Zimmern optioniert, welches nach dem 15.06.2022 frei
          wird.
        </Typography>
        <WalkCard/>
        <KroneCard/>
      </Vertical>
    </div>
  );
}

export { Accommodations as default };
