import { useState } from "react";

export function useLocalStorage(key: string, initialValue: string | null): [string | null, (value: string | null) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? item : initialValue;
    } catch(error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: string | null): void => {
    try {
      if(!!value && (value.length > 0)) {
        setStoredValue(value);
        window.localStorage.setItem(key, value);
        return;
      }
      setStoredValue(null);
      window.localStorage.removeItem(key);
    } catch(error) {
      // A more advanced implementation would handle the error case
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  return [storedValue, setValue];
}
