import styled from "@emotion/styled";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  child?: JSX.Element;
  title: string;
}

const ModalLayout = styled.div`
  padding: 12px;
  padding-top: 0;
`;

export const ContributionModal = ({ onClose, open, child, title }: SimpleDialogProps): JSX.Element => {

  const handleClose = (): void => {
    onClose();
  };

  return (
    <Dialog onClose={ handleClose } open={ open }>
      <DialogTitle>{ title }</DialogTitle>
      <ModalLayout>
        { !!child && child }
      </ModalLayout>
      <DialogActions>
        <Button variant="contained" onClick={ handleClose }>Verstanden</Button>
      </DialogActions>
    </Dialog>
  );
};
