import styled from "@emotion/styled";
import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const SessionExpired = ({ setToken }: { setToken: (value: string | null) => void }): JSX.Element => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setToken(null);
      navigate("/");
    }, 3000);
  });

  return <ColumnLayout>
    <Typography variant="h5" gutterBottom>Sitzung abgelaufen</Typography>
    <Box sx={ { width: "70%", paddingTop: "8px", paddingBottom: "24px" } }>
      <LinearProgress/>
    </Box>

    <Typography>
      Du wirst zum Login weitergeleitet.
    </Typography><Typography>
    Dort kannst du dich erneut anmelden.
    </Typography>
  </ColumnLayout>;
};
