import styled from "@emotion/styled";

const HeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
  url("car.jpg");

  /* Set a specific height */
  height: 35%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const SmallerHeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
  url("rings.jpg");

  /* Set a specific height */
  height: 25%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const HeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 80%;
`;

function Hero({ isLoggedIn }: { isLoggedIn: boolean }): JSX.Element {
  return (
    <HeroImage>
      <HeroText>
        <h1>Jennifer & Michael</h1>
        { isLoggedIn && <><p>Wir sagen Ja zueinander</p>
          <p>01. Oktober 2022</p></> }
      </HeroText>
    </HeroImage>
  );
}

function SmallerHero(): JSX.Element {
  return (
    <SmallerHeroImage>
      <HeroText>
        <h1>Jennifer & Michael</h1>
        <p>Wir sagen Ja zueinander</p>
        <p>01. Oktober 2022</p>
      </HeroText>
    </SmallerHeroImage>
  );
}

export { Hero as default, SmallerHero };
