import { useQuery } from "@apollo/client";
import { HelpOutline, Mail, WhatsApp } from "@mui/icons-material";
import { Box, IconButton, Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import { CenteredLayout } from "../../components/CenteredLayout";
import { Loading } from "../../components/Loading";
import { Spacing } from "../../components/Spacing";
import { selectContribution, setContribution } from "../../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../../data/hooks";
import { GET_MARRIAGE_WITNESSES } from "../../data/queries";
import { MarriageWitness } from "../../models/MarriageWitness";
import { ContributionModal } from "../components/ContributionModal";
import { StepsLayout } from "../StepsLayout";

const ChampagneReceiptText = (): JSX.Element => (<Typography>Nach der Trauung soll es vor der Kirche einen Sektempfang geben.
  Könntet ihr euch vorstellen, Snacks für den Sektempfang beizusteuern?<br/>
  Wir würden uns dann bei Bedarf bei euch melden.</Typography>);

const ProgramText = (): JSX.Element => {
  const { data, error, loading } = useQuery(GET_MARRIAGE_WITNESSES);
  if(error) {
    return <Navigate to="/error" replace/>;
  }
  if(loading) {
    return <Loading/>;
  }
  const witnesses: MarriageWitness[] = data.allMarriageWitnesses;
  return (<Typography>Freut uns, dass ihr Interesse an einem Programmpunktbeitrag habt!<br/>
    Bitte kontaktiert dazu unsere Trauzeugen. <br/> <br/>
    {
      witnesses.map(({ id, name, eMailAddress, phoneNumber }) => <Typography align="center" component="span" sx={ { textAlign: "center" } }
        key={ id }>
        { name } <br/>
        <WhatsApp sx={ { color: "#F7B1B4", verticalAlign: "middle" } }/> { "  " }
        <a style={ { color: "#9FA696" } } href={ `tel:${ phoneNumber }` }>(+49) { phoneNumber }</a>
        <br/>
        <Mail sx={ { color: "#F7B1B4", verticalAlign: "middle" } }/> { "  " }
        <a style={ { color: "#9FA696" } } href={ `mailto:${ eMailAddress }` }>{ eMailAddress }</a> <br/>
        <br/>
      </Typography>)
    }
  </Typography>);
};

const SnackText = (): JSX.Element => (<Typography>
  Für den Sektempfang wären Snacks von Vorteil, die mit der Hand gut essbar sind. Ein Beispiel sind z.B. Pizzaschnecken. <br/>
  Welche Knabbereien könntet ihr euch vorstellen mitzubringen?
</Typography>);

export const ContributionStep = (): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const [openChampagne, setOpenChampagne] = React.useState(false);
  const [openSnacks, setOpenSnacks] = React.useState(false);
  const dispatch = useAppDispatch();
  const contributionInfo = useAppSelector(selectContribution);

  const handleClickOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return <StepsLayout>
    <Typography sx={ { maxWidth: "500px" } }>Wer möchte, kann sich sehr gerne auf unserer Hochzeit einbringen. Wir sind um jeden
      (Essens-)Beitrag
      dankbar!</Typography>
    <Spacing/>
    <CenteredLayout>
      <Box>
        <Typography>Beitrag Programmpunkt <IconButton onClick={ handleClickOpen } size="small"><HelpOutline
          sx={ { color: "#fbc02d" } }/></IconButton></Typography>
        <ContributionModal
          title={ "Beitrag Programmpunkt" }
          open={ open }
          onClose={ handleClose }
          child={ <ProgramText/> }
        />
        <Stack direction="row" spacing={ 1 } alignItems="center" sx={ { marginLeft: 1.5 } }>
          <Typography>Nein</Typography>
          <Switch
            checked={ contributionInfo?.provideEntertainment || false }
            color="success"
            onChange={ (event) => dispatch(setContribution({ ...contributionInfo, provideEntertainment: event.target.checked })) }
          />
          <Typography>Ja</Typography>
        </Stack>
        <Spacing/>
        <Typography>Snackbeitrag zum Sektempfang <IconButton onClick={ () => setOpenChampagne(true) } size="small"><HelpOutline
          sx={ { color: "#fbc02d" } }/></IconButton></Typography>
        <ContributionModal
          title={ "Snacks zum Sektempfang" }
          open={ openChampagne }
          onClose={ () => setOpenChampagne(false) }
          child={ <ChampagneReceiptText/> }
        />
        <Stack direction="row" spacing={ 1 } alignItems="center" sx={ { marginLeft: 1.5 } }>
          <Typography>Nein</Typography>
          <Switch
            checked={ contributionInfo?.provideSnacks || false }
            color="success"
            onChange={ (event) => dispatch(setContribution({ ...contributionInfo, provideSnacks: event.target.checked })) }
          />
          <Typography>Ja</Typography>
        </Stack>
        <Spacing/>
        <Typography>Ideen Snacks <IconButton onClick={ () => setOpenSnacks(true) } size="small"><HelpOutline
          sx={ { color: "#fbc02d" } }/></IconButton></Typography>
        <ContributionModal
          title={ "Snackideen" }
          open={ openSnacks }
          onClose={ () => setOpenSnacks(false) }
          child={ <SnackText/> }
        />
        <TextField
          label="Bitte Snackvorschläge eingeben"
          multiline
          rows={ 3 }
          value={ contributionInfo?.snackSuggestions || "" }
          onChange={ (event) => dispatch(setContribution({ ...contributionInfo, snackSuggestions: event.target.value })) }
          sx={ { marginLeft: 1.5, minWidth: "300px", marginTop: 1, zIndex: 0 } }
        />
      </Box>
    </CenteredLayout>
  </StepsLayout>
  ;
};
