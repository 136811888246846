import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Loading = (): JSX.Element => (<ColumnLayout>
  <CircularProgress/>
  <Typography>Bitte habe einen Augenblick Geduld</Typography>
</ColumnLayout>);
