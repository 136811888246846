import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FoodIntolerance, Guest } from "../models/Guest";
import { RootState } from "./store";

export enum EatingBehaviour {
  MEAT = 1,
  VEGETARIAN = 2,
  VEGAN = 3,
}

export const EatingBehaviourText = ["", "Fleisch", "Vegetarisch", "Vegan"];

export type EatingBehaviourType = EatingBehaviour;

export interface EatingInformation {
  age?: number;
  foodIntolerances?: FoodIntolerance[];
  eatingBehaviour?: EatingBehaviour;
}

export interface EatingInformationPairs {
  [key: string]: EatingInformation;
}

export interface GuestContribution {
  provideSnacks?: boolean;
  snackSuggestions?: string;
  provideEntertainment?: boolean;
}

interface GuestState {
  guests: Guest[];
  confirmedPairs?: { [key: string]: boolean };
  eatingInformation?: EatingInformationPairs;
  contribution?: GuestContribution;
  invitationId: string;
}

const initialState: GuestState = {
  guests: [],
  invitationId: "",
};

export const guestSlice = createSlice({
  name: "guestInformation",
  initialState,
  reducers: {
    setGuests: (state, action: PayloadAction<Guest[]>) => ({
      ...state, guests: action.payload,
    }),
    setInvitationId: (state, action: PayloadAction<string>) => ({
      ...state, invitationId: action.payload,
    }),
    updateGuest: (state, action: PayloadAction<Guest>) => {
      const updatedGuestList = state.guests.filter(guest => guest.id !== action.payload.id);
      return { ...state, guests: [...updatedGuestList, action.payload] };
    },
    setConfirmedPairs: (state, action: PayloadAction<{ [key: string]: boolean }>) => ({
      ...state, confirmedPairs: action.payload,
    }),
    setEatingInformation: (state, action: PayloadAction<{ [key: string]: EatingInformation }>) => {
      const eatingInformation: { [key: string]: EatingInformation } = action.payload;
      return { ...state, eatingInformation };
    },
    setAge: (state, action: PayloadAction<[string, number]>) => {
      const guestEatingInf = (state.eatingInformation && state.eatingInformation[action.payload[0]])
        ? { ...state.eatingInformation[action.payload[0]], age: action.payload[1] }
        : { age: action.payload[1] };
      return {
        ...state,
        eatingInformation: { ...state.eatingInformation, [action.payload[0]]: guestEatingInf },
      };
    },
    setEatingBehaviour: (state, action: PayloadAction<[string, EatingBehaviour]>) => {
      const guestEatingInf = (state.eatingInformation && state.eatingInformation[action.payload[0]])
        ? { ...state.eatingInformation[action.payload[0]], eatingBehaviour: action.payload[1] }
        : { eatingBehaviour: action.payload[1] };
      return {
        ...state,
        eatingInformation: { ...state.eatingInformation, [action.payload[0]]: guestEatingInf },
      };
    },
    setFoodIntolerances: (state, action: PayloadAction<[string, FoodIntolerance[]]>) => {
      const guestEatingInf = (state.eatingInformation && state.eatingInformation[action.payload[0]])
        ? { ...state.eatingInformation[action.payload[0]], foodIntolerances: action.payload[1] }
        : { foodIntolerances: action.payload[1] };
      return {
        ...state,
        eatingInformation: { ...state.eatingInformation, [action.payload[0]]: guestEatingInf },
      };
    },
    addFoodIntolerance: (state, action: PayloadAction<[string, string]>) => {
      const currentIntolerances = (state.eatingInformation?.[action.payload[0]]?.foodIntolerances) || [];
      const foodIntolerances = [...currentIntolerances, { name: action.payload[1] }];
      const guestEatingInf = (state.eatingInformation && state.eatingInformation[action.payload[0]]) || {};
      return {
        ...state,
        eatingInformation: { ...state.eatingInformation, [action.payload[0]]: { ...guestEatingInf, foodIntolerances } },
      };
    },
    removeFoodIntolerance: (state, action: PayloadAction<[string, string]>) => {
      const currentIntolerances = (state.eatingInformation && state.eatingInformation[action.payload[0]].foodIntolerances) || [];
      const foodIntolerances = currentIntolerances.filter(intolerance => intolerance.name !== action.payload[1]);
      const guestEatingInf = (state.eatingInformation && state.eatingInformation[action.payload[0]]) || {};
      return {
        ...state,
        eatingInformation: { ...state.eatingInformation, [action.payload[0]]: { ...guestEatingInf, foodIntolerances } },
      };
    },
    setContribution: (state, action: PayloadAction<GuestContribution>) => ({
      ...state, contribution: action.payload,
    }),
    clearGuestInformation: state => ({
      guests: state.guests,
      invitationId: state.invitationId,
    }),
  },
});

export const {
  setGuests, setInvitationId, setConfirmedPairs, setEatingInformation, updateGuest,
  setEatingBehaviour, addFoodIntolerance, removeFoodIntolerance, setAge, setContribution, clearGuestInformation,
} = guestSlice.actions;

export const selectGuests = (state: RootState): Guest[] => state.guestInformation.guests;
export const selectInvitationId = (state: RootState): string => state.guestInformation.invitationId;
export const selectAllGuestInfoSettled = (state: RootState): boolean => {
  const currentGuests = state.guestInformation.guests;
  return currentGuests.every(guest => {
    if((guest.confirmed === undefined) || (guest.confirmed === null)) {
      return false;
    }
    if(guest.confirmed === false) {
      return true;
    }
    return !(!guest.age || !guest.eatingBehaviour);

  });
};

export const selectAllDeclined = (state: RootState): boolean => {
  const confirmedPairs = state.guestInformation.confirmedPairs;
  if(!confirmedPairs) {
    return false;
  }
  return Object.values(confirmedPairs).every(x => x === false);
};
export const selectConfirmedPairs = (state: RootState): { [key: string]: boolean } | undefined => state.guestInformation.confirmedPairs;
export const selectConfirmedGuests = (state: RootState): string[] => Object.keys(state.guestInformation.confirmedPairs || {})
  .filter(guestName => state.guestInformation.confirmedPairs?.[guestName]);
export const selectEatingInformation = (state: RootState): { [key: string]: EatingInformation } | undefined =>
  state.guestInformation.eatingInformation;
export const selectContribution = (state: RootState): GuestContribution | undefined => state.guestInformation.contribution;

export default guestSlice.reducer;
