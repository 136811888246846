import styled from "@emotion/styled";
import { Celebration, FavoriteRounded, SentimentDissatisfiedTwoTone } from "@mui/icons-material";
import { Box, CircularProgress, Divider, FormLabel, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CenteredLayout } from "../../components/CenteredLayout";
import { selectConfirmedPairs, selectGuests, setConfirmedPairs } from "../../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../../data/hooks";
import { StepsLayout } from "../StepsLayout";

const CenteredLaout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
`;

function RegistrationSwitches(): JSX.Element {
  const dispatch = useAppDispatch();
  const guests = useAppSelector(selectGuests);
  const confirmedGuests = useAppSelector(selectConfirmedPairs);

  React.useEffect(() => {
    if(!confirmedGuests && (guests.length > 0)) {
      const guestConfirmation: { [key: string]: boolean } = guests.reduce(
        (guestConf, { name, confirmed }) => ({
          ...guestConf,
          [name]: confirmed ?? false,
        }),
        {},
      );
      dispatch(setConfirmedPairs(guestConfirmation));
    }
  }, [confirmedGuests, dispatch, guests]);

  if(!confirmedGuests) {
    return (<Box sx={ { display: "flex" } }>
      <CircularProgress/>
    </Box>
    );
  }

  const handlePersonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ): void => {
    const tempState = { ...confirmedGuests, [key]: event.target.checked };
    dispatch(setConfirmedPairs(tempState));
  };

  const switchChildren = (
    <Box sx={ { display: "flex", flexDirection: "column", ml: 3, mt: 2 } }>
      { Object.keys(confirmedGuests).map((guestKey) => (
        <FormControlLabel
          key={ guestKey }
          label={ guestKey }
          sx={ { mb: 1 } }
          control={
            <Switch
              checked={ confirmedGuests[guestKey] }
              onChange={ (event) => handlePersonChange(event, guestKey) }
              checkedIcon={ <Celebration color="success"/> }
              icon={ <SentimentDissatisfiedTwoTone color="error" sx={ {
                position: "absolute", top: "19px", left: "10%",
                backgroundColor: "white",
                borderRadius: "50%",
                transform: "translateY(-50%)",
                width: 24,
                height: 24,
              } }/> }
            />
          }
        />
      )) }
    </Box>
  );

  return (
    <CenteredLaout>
      <Divider sx={ { width: "300px" } }><FormLabel>Wer kommt zur Hochzeit?</FormLabel> </Divider>
      { switchChildren }
    </CenteredLaout>
  );
}

export const Zusage = (): JSX.Element => {
  return (
    <StepsLayout>
      <CenteredLayout>
        <Typography sx={ { display: "flex", flexDirection: "row", justifyContent: "center", maxWidth: "340px" } }>
          Bitte teilt uns bis zum <Typography component={ "span" } fontWeight="bold" display="contents">15.06.2022</Typography> mit, ob ihr
          bzw. wer von euch bei unserer Hochzeit dabei sein kann. Wir freuen uns auf zahlreiche Zusagen!
        </Typography>
        <Typography sx={ { display: "flex", flexDirection: "row", justifyContent: "center" } }>
          <FavoriteRounded
            sx={ { color: "#F7B1B4" } }/>
        </Typography>
        <RegistrationSwitches/>
      </CenteredLayout>
    </StepsLayout>
  );
};
