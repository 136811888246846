import { gql } from "@apollo/client";

export const UPDATE_GUEST = gql`
    mutation UpdateGuest($guest: UpdateGuestInput!) {
        updateGuest(guest: $guest) {
            id
            age
            name
            confirmed
            eatingBehaviour
            foodIntolerances {
                name
            }
        }
    }
`;

export const UPDATE_INVITATION = gql`
    mutation UpdateInvitation($invitation: UpdateInvitationInput!) {
        updateInvitation(invitation: $invitation) {
            guid
            provideEntertainment
            provideSnacks
            snackSuggestions
        }
    }
`;

