import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import * as React from "react";

export default function TimeLine(): JSX.Element {
  return (
    <React.Fragment>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            13:30 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Einlass Kirche</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            14:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Trauung</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            15:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Sektempfang</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            15:30 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Gruppenfotos</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            16:30 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Empfang Romantiksaal "Walksches Haus"</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            17:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Abendessen</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            20:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Hochzeitstorte</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            22:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Party</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="#F7B1B4">
            00:00 Uhr
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot/>
            <TimelineConnector/>
          </TimelineSeparator>
          <TimelineContent>Kleine Stärkung</TimelineContent>
        </TimelineItem>
      </Timeline>
    </React.Fragment>
  );
}
