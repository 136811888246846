import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ButtonHero } from "./ButtonHero";

const KraftPaperHero = styled(ButtonHero)`
  background-color: #A2856D;
`;

export const InformationHero = (): JSX.Element => {
  const navigate = useNavigate();

  return <KraftPaperHero>
    <Typography sx={ { color: "white", margin: "4px 14px 4px 14px", maxWidth: "420px", filter: "brightness(99%)" } }>Hier kommt ihr zu
      weiteren Informationen wie
      Anfahrt, Ablauf, Übernachtungen etc.</Typography>
    <Button
      variant="outlined"
      onClick={ () => navigate("/information") }
      sx={ { height: "46px", color: "white", border: "2px solid white", marginTop: "8px", filter: "brightness(99%)" } }
    >
      Zu den Informationen
    </Button>
  </KraftPaperHero>;
};
