import { useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { UPDATE_GUEST } from "../../data/mutations";
import { GET_GUESTS_FROM_INVITATIONS } from "../../data/queries";
import { Guest } from "../../models/Guest";

export default function RenameDialog(
  { guest, open, handleClose }: { guest: Guest, open: boolean, handleClose: () => void },
): JSX.Element {
  const [name, setName] = React.useState(guest.name);
  const [setNameMutation] = useMutation(UPDATE_GUEST, { refetchQueries: [{ query: GET_GUESTS_FROM_INVITATIONS }] });

  const handleSave = (): void => {
    const guestNameUpdate = { id: guest.id, name };
    setNameMutation({ variables: { guest: guestNameUpdate } }).then(() => handleClose()).catch(() => {
      handleClose();
    });
  };

  const onClose = (): void => {
    handleClose();
  };

  return (
    <div>
      <Dialog open={ open } onClose={ onClose }>
        <DialogTitle>Name anpassen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Es tut uns leid falls wir deinen Namen falsch geschrieben haben! :(
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={ name }
            onChange={ (event) => setName(event.target.value) }
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={ onClose }>Abbruch</Button>
          <Button onClick={ handleSave }>Speichern</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
