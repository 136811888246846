import { AddCircle, Delete } from "@mui/icons-material";
import { Box, Chip, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { Fragment, useState } from "react";
import { ErrorSnack } from "../../components/ErrorSnack";
import { addFoodIntolerance, removeFoodIntolerance } from "../../data/guestSlice";
import { useAppDispatch } from "../../data/hooks";
import { FoodIntolerance } from "../../models/Guest";

export const IntoleranceList = ({ intolerances, guestName }: {
  intolerances: FoodIntolerance[] | undefined,
  guestName: string
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [intolerance, setIntolerance] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if(reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleAddIntolerance = (guestName: string): void => {
    if(intolerance.trim().length === 0) {
      setSnackOpen(true);
      return;
    }
    const alreadyAdded = intolerances?.some(
      intol => intol.name.trim().toLowerCase() === intolerance.trim().toLowerCase());
    if(alreadyAdded) {
      setSnackOpen(true);
      return;
    }
    dispatch(addFoodIntolerance([guestName, intolerance]));
    setIntolerance("");
  };

  return <Fragment>
    <FormControl size="small" sx={ { m: 1, width: "31ch" } } variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password" sx={ { zIndex: 0 } }>Unverträglichkeit</InputLabel>
      <OutlinedInput
        size="small"
        type={ "text" }
        value={ intolerance }
        onChange={ (event) => {
          setIntolerance(event.target.value);
          setSnackOpen(false);
        } }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={ () => handleAddIntolerance(guestName) }
              onMouseDown={ (event) => event.preventDefault() }
              edge="end"
            >
              <AddCircle/>
            </IconButton>
          </InputAdornment>
        }
        label="Intoleranz hinzufügen"
      />

    </FormControl>
    <ErrorSnack
      text={ `Bitte gebe für ${ guestName } eine andere Unverträglichkeit ein, da 
      ${ intolerance.length ? "diese bereits existiert" : "das Feld leer ist" }` }
      open={ snackOpen } handleClose={ handleClose }/>

    <Box sx={ { display: "flex", flexDirection: "row", maxWidth: "36ch", ml: "12px", flexWrap: "wrap", gap: "8px 10px" } }>
      { intolerances?.map(({ name }) => (
        <Chip
          key={ guestName + name }
          label={ name }
          onDelete={ () => dispatch(removeFoodIntolerance([guestName, name])) }
          deleteIcon={ <Delete/> }
          variant="outlined"
          sx={ { mr: "8px" } }
        />
      )) }
    </Box>
  </Fragment>;
};
