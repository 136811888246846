import { configureStore } from "@reduxjs/toolkit";
import guestInformationReducer from "./guestSlice";

export const store =  configureStore({
  reducer: {
    guestInformation: guestInformationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
