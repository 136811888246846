import { useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { Hotel } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CameraEnhanceRoundedIcon from "@mui/icons-material/CameraEnhanceRounded";
import ChurchRoundedIcon from "@mui/icons-material/ChurchRounded";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import { Button, Typography } from "@mui/material";
import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "../App.css";
import { SmallerHero } from "../components/Hero";
import { Loading } from "../components/Loading";
import SimpleAppBar from "../components/SimpleAppBar";
import { GET_GUESTS_FROM_INVITATIONS } from "../data/queries";

const ButtonNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 36px;
  height: 50%;
`;

const NavigationButton = styled(Button)`
  height: 58px;
  width: 330px;
`;

function Overview(): JSX.Element {
  const { loading, error, data } = useQuery(GET_GUESTS_FROM_INVITATIONS);
  const navigate = useNavigate();

  if(error) {
    return <Navigate to="/error" replace/>;
  }
  return (
    <div className="App">
      <SimpleAppBar backPath="/"/>
      <SmallerHero/>
      <Typography variant="subtitle1" sx={ { marginTop: "12px", marginBottom: "12px" } }>
        Auf den folgenden Seiten findest du einige Informationen über diesen besonderen Tag! :)
      </Typography>
      <ButtonNavigationContainer>
        <NavigationButton
          onClick={ () => navigate("/locations") }
          variant="contained" startIcon={ <ChurchRoundedIcon/> }>Die Orte</NavigationButton>
        <NavigationButton
          onClick={ () => navigate("/timeline") }
          variant="contained" startIcon={ <AccessTimeIcon/> }>Der zeitliche Ablauf</NavigationButton>
        <NavigationButton
          onClick={ () => navigate("/presents") }
          variant="contained"
          startIcon={ <RedeemRoundedIcon/> }
        >Wunschliste des
          Brautpaars</NavigationButton>
        <NavigationButton
          onClick={ () => navigate("/accommodations") }
          variant="contained"
          startIcon={ <Hotel/> }
        >Übernachtungsmöglichkeiten</NavigationButton>
        { loading ? <Loading/> : <NavigationButton disabled={ !data.myInvitation.photoUploadLink } variant="contained"
          href={ data.myInvitation.photoUploadLink }
          startIcon={ <CameraEnhanceRoundedIcon/> }>Galerie
          01.10.2022</NavigationButton> }
      </ButtonNavigationContainer>
    </div>
  );
}

export default Overview;
