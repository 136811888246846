import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { EatingBehaviour, EatingBehaviourText, setEatingBehaviour } from "../../data/guestSlice";
import { useAppDispatch } from "../../data/hooks";

export const DietSelect = ({ eatingBehaviour, guestName }: { eatingBehaviour: number | undefined, guestName: string }): JSX.Element => {
  const dispatch = useAppDispatch();

  return <FormControl size="small" required sx={ { m: 1, width: "21ch" } }>
    <InputLabel sx={ { zIndex: 0 } }>Ernährungsweise</InputLabel>
    <Select
      size="small"
      value={ eatingBehaviour ? `${ eatingBehaviour }` : "" }
      label="Ernährungsweise *"
      onChange={ (event) => dispatch(setEatingBehaviour([guestName, parseInt(event.target.value)])) }
    >
      <MenuItem value={ 1 }>{ EatingBehaviourText[EatingBehaviour.MEAT] }</MenuItem>
      <MenuItem value={ 2 }>{ EatingBehaviourText[EatingBehaviour.VEGETARIAN] }</MenuItem>
      <MenuItem value={ 3 }>{ EatingBehaviourText[EatingBehaviour.VEGAN] }</MenuItem>
    </Select>
  </FormControl>;
};
