import { gql, useMutation } from "@apollo/client";
import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Snackbar, TextField, Typography } from "@mui/material";
import { useReducer } from "react";
import { Action } from "../../utils/Action";
import { ButtonHero } from "./ButtonHero";

// noinspection GraphQLUnresolvedReference
const LOGIN = gql`
    mutation Login($secret: String!) {
        login(loginModel: { secret: $secret })
    }
`;

enum LoginTypes {
  UpdateSecret = "update_secret",
  IsEmpty = "set_empty_secret",
  IsInvalid = "set_invalid_secret",
  FetchToken = "set_fetching_token",
  FetchSuccessful = "set_fetch_successful",
  CloseSnack = "set_Snacki"
}

interface LoginState {
  loading: boolean;
  invalid: boolean;
  helperText: string;
  secret: string;
  open: boolean;
}

const YellowHero = styled(ButtonHero)`
  background-color: #FAD5B3;
`;

const loginReducer = (
  state: LoginState,
  action: Action<Exclude<LoginTypes, LoginTypes.UpdateSecret>> | Action<LoginTypes.UpdateSecret, { secret: string }>,
): LoginState => {
  switch(action.type) {
    case LoginTypes.UpdateSecret:
      return { ...state, secret: action.secret, invalid: false, open: false };
    case LoginTypes.IsEmpty:
      return { ...state, loading: false, invalid: true, helperText: "Bitte gib dein Passwort ein.", open: true };
    case LoginTypes.IsInvalid:
      return { ...state, loading: false, invalid: true, helperText: "Falsches Passwort. Bitte versuche es erneut.", open: true };
    case LoginTypes.FetchToken:
      return { ...state, loading: true, invalid: false, helperText: "", open: false };
    case LoginTypes.FetchSuccessful:
      return { ...state, loading: false, invalid: false, helperText: "", open: false };
    case LoginTypes.CloseSnack:
      return { ...state, open: false };
    default:
      throw new Error();
  }
};

const ErrorSnack = ({ open, handleClose, text }: { open: boolean, handleClose: () => void, text: string }): JSX.Element => <Snackbar
  open={ open }
  autoHideDuration={ 6000 }
  onClose={ handleClose }>
  <Alert onClose={ handleClose } severity="error" sx={ { width: "100%" } }>
    { text }
  </Alert>
</Snackbar>;

export const LoginHero = ({ setToken }: { setToken: (secret: string | null) => void }): JSX.Element => {
  const [loginMutation] = useMutation(LOGIN);
  const initialState: LoginState = {
    loading: false,
    invalid: false,
    helperText: "",
    secret: "",
    open: false,
  };
  const [{ secret, loading, invalid, helperText, open }, dispatch] = useReducer(loginReducer, initialState);

  const login = (): void => {
    dispatch({ type: LoginTypes.FetchToken });
    if(secret.length === 0) {
      dispatch({ type: LoginTypes.IsEmpty });
      return;
    }
    loginMutation({ variables: { secret } })
      .then(({ data: { login } }) => {
        if(setToken !== null) {
          setToken(login);
        }
        dispatch({ type: LoginTypes.FetchSuccessful });
      })
      .catch((_) => {
        dispatch({ type: LoginTypes.IsInvalid });
      });
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
    if(reason === "clickaway") {
      return;
    }
    dispatch({ type: LoginTypes.CloseSnack });
  };

  return (
    <YellowHero>
      <Typography sx={ { color: "#A2856D", margin: "4px 14px 8px 14px", filter: "brightness(70%)", maxWidth: "420px" } }>
        Bitte loggt euch zuerst mit dem Passwort ein, das ihr auf eurer Einladung unter dem QR-Code findet.
        Im Anschluss könnt ihr zu- oder absagen und habt die Möglichkeit, weitere Informationen über
        diesen besonderen Tag nachzulesen.
      </Typography>
      <TextField
        error={ invalid }
        value={ secret }
        onChange={ (event) => dispatch({ type: LoginTypes.UpdateSecret, secret: event.target.value }) }
        id="secret-text-field"
        label="Passwort"
        variant="outlined"
        size="medium"
        sx={ { width: "280px" } }
      />
      <LoadingButton
        variant="contained"
        onClick={ login }
        sx={ {
          height: "45px", color: "#FAD5B3", border: "2px solid #E4867C", backgroundColor: "#E4867C", fontWeight: "600", marginTop: "8px",
          "&:hover": {
            backgroundColor: "#E4867C",
            filter: "brightness(75%)",
          },
        } }
        loading={ loading }
      >
        Anmelden
      </LoadingButton>
      <ErrorSnack open={ open } handleClose={ handleClose } text={ helperText }/>
    </YellowHero>
  );
};
