import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function SimpleAppBar({
  backPath,
  onClickLogo,
  hideBackButton = false,
}: { backPath: string, onClickLogo?: () => void, hideBackButton?: boolean }): JSX.Element {
  const navigate = useNavigate();
  // TODO: Display none für hideButton + padding anpassen
  return (
    <Box sx={ { textAlign: "center" } }>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={ { mr: 2 } }
            onClick={ () => navigate(backPath) }
          >
            <ArrowBack/>
          </IconButton>

          <Typography variant="h6" component="div" sx={ { flexGrow: 1, marginRight: "36px" } }>
            <Button
              onClick={ () => {
                onClickLogo ?
                  onClickLogo() : navigate("/");
              } }
              color="inherit"
              sx={ { textTransform: "none" } }>
              <Typography variant="h6">Jennifer & Michael </Typography>
            </Button>
          </Typography>

        </Toolbar>
      </AppBar>
    </Box>
  );
}
