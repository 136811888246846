import styled from "@emotion/styled";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Card, CardActions, CardContent, CardHeader, Chip, Collapse, IconButton, Typography } from "@mui/material";
import { IconButtonProps } from "@mui/material/IconButton";
import { styled as muiStyled } from "@mui/material/styles";
import * as React from "react";
import { RowLayout } from "../../components/RowLayout";
import { EatingBehaviour, EatingBehaviourText } from "../../data/guestSlice";
import { Guest } from "../../models/Guest";
import RenameDialog from "./RenameDialog";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const StyledName = styled.div`
  padding-left: 16px;
`;

const ExpandMore = muiStyled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton { ...other } />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function GuestCard({ guest }: { guest: Guest }): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = (): void => {
    setExpanded(!expanded);
  };

  const [nameOpen, setNameOpen] = React.useState(false);
  const handleClose = (): void => {
    setNameOpen(false);
  };

  return (
    <Card raised sx={ { maxWidth: 340, minWidth: 300, marginBottom: 2, marginTop: "8px" } }>
      <CardHeader titleTypographyProps={ { fontSize: 18 } }
        component="div"
        title={ <StyledName>
          <Typography onClick={ () => setNameOpen(true) } component="span" sx={ { fontSize: 18 } }>{ guest.name }</Typography>
          <IconButton aria-label="Name ändern"
            onClick={ () => setNameOpen(true) }
            sx={ { top: "-12px" } }>
            <EditIcon sx={ { fontSize: "16px" } }/>
          </IconButton></StyledName> }

      />
      <RenameDialog guest={ guest } open={ nameOpen } handleClose={ handleClose }/>
      <CardContent sx={ { display: "flex", flexDirection: "column", alignItems: "flex-start", paddingTop: 0.5, paddingBottom: 0 } }>
        <Box sx={ { display: "flex", flexDirection: "row" } }>
          <Typography sx={ { fontSize: 15, margin: 0, paddingTop: "4px" } } color="text.secondary" gutterBottom>
            Rückmeldung:
          </Typography>
          <Chip
            variant="outlined"
            color={ guest.confirmed ? "success" : (guest.confirmed === false) ? "error" : "warning" }
            label={ guest.confirmed ? "Zugesagt" : (guest.confirmed === false) ? "Abgesagt" : "ausstehend" }
            sx={ { marginLeft: 1.5, marginBottom: 0.5 } }
          />
        </Box>
      </CardContent>
      <CardActions disableSpacing
        sx={ { paddingTop: 0, paddingBottom: 0, visibility: !guest.confirmed ? "hidden" : "inherit" } }>
        <ExpandMore
          size="small"
          expand={ expanded }
          onClick={ handleExpandClick }
          aria-expanded={ expanded }
          aria-label="zeige mehr"
        >
          <ExpandMoreIcon/>
        </ExpandMore>
      </CardActions>
      <Collapse in={ expanded } timeout="auto" unmountOnExit>
        <CardContent sx={ { display: "flex", flexDirection: "column", alignItems: "flex-start" } }>
          <Typography sx={ { alignItems: "center", paddingBottom: 1, display: "inline-block" } } color="text.primary" variant="body1">
            Weitere Informationen
          </Typography>
          <RowLayout>
            <Typography sx={ { fontSize: 15 } } color="text.secondary">
              Alter:
            </Typography>
            <Typography sx={ { marginLeft: 1.5, fontSize: 15 } } color="text.primary">
              { guest.age ? guest.age : "-" }
            </Typography>
          </RowLayout>
          <RowLayout>
            <Typography sx={ { fontSize: 15 } } color="text.secondary">
              Ernährungsweise:
            </Typography>
            <Typography sx={ { marginLeft: 1.5, fontSize: 15 } } color="text.primary">
              { guest.eatingBehaviour ? EatingBehaviourText[parseInt(EatingBehaviour[guest.eatingBehaviour])] : "-" }
            </Typography>
          </RowLayout>
          <Typography sx={ { fontSize: 15 } } color="text.secondary">
            Unverträglichkeiten: { !guest.foodIntolerances?.length && "-" }
          </Typography>
          <Typography sx={ { fontSize: 15, maxWidth: 250, paddingLeft: 1.2 } } color="text.primary">
            { !!guest?.foodIntolerances?.length
              && guest.foodIntolerances.reduce(
                (prev, current) => {
                  if(prev === "") {
                    return current.name;
                  }
                  return `${ prev }, ${ current.name }`;
                }, "") }
          </Typography>


        </CardContent>
      </Collapse>
    </Card>
  );
}
