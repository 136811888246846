import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import { CenteredLayout } from "../../components/CenteredLayout";
import { RowLayout } from "../../components/RowLayout";
import { Spacing } from "../../components/Spacing";
import {
  EatingBehaviour,
  EatingInformation,
  selectConfirmedGuests,
  selectEatingInformation,
  selectGuests,
  setEatingInformation,
} from "../../data/guestSlice";
import { useAppDispatch, useAppSelector } from "../../data/hooks";
import { AgeTextField } from "../components/AgeTextField";
import { DietSelect } from "../components/DietSelect";
import { IntoleranceList } from "../components/IntoleranceList";
import { StepsLayout } from "../StepsLayout";

export const FoodStep = (): JSX.Element => {
  const foodInfo = useAppSelector(selectEatingInformation);
  const confirmedGuests = useAppSelector(selectConfirmedGuests);
  const guests = useAppSelector(selectGuests);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if(!foodInfo && (guests.length > 0)) {
      const initialFoodInfo: { [key: string]: EatingInformation } = guests.reduce(
        (guestConf, { name, age, eatingBehaviour: behavior, foodIntolerances }) => {
          const hasConfirmed = confirmedGuests.some((guestName) => guestName === name);
          const eatingBehaviour = behavior && EatingBehaviour[behavior];
          if(!hasConfirmed) {
            return guestConf;
          }
          return ({
            ...guestConf,
            [name]: { eatingBehaviour, age, foodIntolerances },
          });
        },
        {},
      );
      dispatch(setEatingInformation(initialFoodInfo));
    }
  }, [confirmedGuests, dispatch, guests, foodInfo]);

  return <StepsLayout>
    <CenteredLayout>
      <Typography sx={ { display: "flex", flexDirection: "row", justifyContent: "center", maxWidth: "340px" } }>
        Hier könnt ihr eure Ernährungsweise und Unverträglichkeiten eintragen, damit wir diese bei der Menüplanung berücksichtigen können.
        Beim Alter solltet ihr euer Alter zum Zeitpunkt der Trauung (1.10.2022) eingeben.
      </Typography>
      { confirmedGuests.map(guestName =>
        <Fragment key={ guestName }>
          <h4>{ guestName }</h4>
          <RowLayout>
            <AgeTextField guestName={ guestName } age={ foodInfo?.[guestName]?.age }/>
            <Spacing/>
            <DietSelect guestName={ guestName } eatingBehaviour={ foodInfo?.[guestName]?.eatingBehaviour }/>
          </RowLayout>
          <IntoleranceList guestName={ guestName } intolerances={ foodInfo?.[guestName]?.foodIntolerances }/>
        </Fragment>) }
    </CenteredLayout>
  </StepsLayout>;
};
