import styled from "@emotion/styled";
import { MapOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import SimpleAppBar from "../components/SimpleAppBar";

const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 12px;
  align-items: center;
`;

const SmallerHeroImage = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  url("places.jpg");

  /* Set a specific height */
  height: 20%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const SmallHeroText = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FAD5B3;
  font-size: 24px;
`;

function Locations(): JSX.Element {
  return (
    <div className="App">
      <SimpleAppBar backPath="/information"/>
      <SmallerHeroImage>
        <SmallHeroText>
          Die wichtigsten Orte
        </SmallHeroText>
      </SmallerHeroImage>
      <Vertical>
        <Card sx={ { maxWidth: 375, minWidth: 320, marginBottom: "16px" } }>
          <CardMedia
            component="img"
            height="180"
            image="https://static3.ka-news.de/storage/image/8/5/7/8/2038758_ka-2015-760_1wnw7B_2PHCqH.webp"
            alt="Bild von St. Michael in Weingarten Baden"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Kirche St. Michael Weingarten (Baden)
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Kirchstraße 1 <br/>
              76356 Weingarten (Baden) <br/><br/>
              Hier findet ab ca. 14 Uhr unsere Trauung statt.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" endIcon={ <MapOutlined/> }
              href="https://goo.gl/maps/sjbFEgU7tx7JUg8J9" sx={ { color: "#A2856D" } }>Google
              Maps</Button>
          </CardActions>
        </Card>
        <Card sx={ { maxWidth: 375, minWidth: 320, marginBottom: "16px" } }>
          <CardMedia
            component="img"
            height="180"
            image="https://www.meinort-weingarten.de/wp-content/uploads/2019/05/Kirchplatz-Terrasse-MeinOrt-672x372.jpg"
            alt="Bild der Bachterrasse"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Bachterassen
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Kirchstraße 3-1 <br/>
              76356 Weingarten (Baden)<br/> <br/>
              Nach der Trauung werden wir uns hier versammeln um mit Sekt auf den Bund der Ehe anstoßen zu können.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" endIcon={ <MapOutlined/> } href="https://goo.gl/maps/vG9rFwEVd4NDp9at5" sx={ { color: "#A2856D" } }>Google
              Maps</Button>
          </CardActions>
        </Card>
        <Card sx={ { maxWidth: 375, minWidth: 320, marginBottom: "16px" } }>
          <CardMedia
            component="img"
            height="180"
            image="https://www.walksches-haus.de/wp-content/uploads/2017/08/20170929_Freistellung_2000.jpg"
            alt="Bild vom Walkschen Haus in Weingarten Baden"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Walksches Haus
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Marktplatz 7<br/>
              76356 Weingarten <br/><br/>
              Im Walkschen Haus werden wir gemeinsam diesen besonderen Tag feiern und bis in die Morgenstunde das Tanzbein schwingen. <br/>
              Des Weiteren habt ihr die Möglichkeit hier zu übernachten, siehe auch <Link
                to="/accommodations">Übernachtungsmöglichkeiten</Link>.
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" endIcon={ <MapOutlined/> } href="https://goo.gl/maps/YQhQYz4hu2fQeMXK6" sx={ { color: "#A2856D" } }>Google
              Maps</Button>
          </CardActions>
        </Card>
      </Vertical>
    </div>

  );
}

export default Locations;
